












































































import { Vue, Component } from "vue-property-decorator"
import RegistrationRepository from "@/api/RegistrationRepository"
import { IRegistrationRequest } from "@/models/IRegistrationRequest"
import PasswordInput from "@/components/login/PasswordInput.vue"

const PARTICIPANT_CODE_REGEX = /^[a-zA-Z]{4}[0-9]{2}$/

@Component({
  components: {
    PasswordInput
  }
})
export default class RegistrationForm extends Vue {
  valid: boolean = false
  successful: boolean = false
  token_provided: boolean = false
  code_help: boolean = false
  registration: IRegistrationRequest = {
    email: "",
    password: "",
    token: "",
    participant_code: "AABB00" // dummy participant code until clinical study will take place
  }
  error: IError = {
    email: "",
    token: "",
    participant_code: ""
  }
  rules: any = {
    email: [
      (v: string) => !!v || this.$t("email_validate.missing"),
      (v: string) => /.+@.+/.test(v) || this.$t("email_validate.invalid")
    ],
    token: [(v: string) => !!v || this.$t("registration.validation.token_missing")],
    participant_code: [
      (v: string) => !!v || this.$t("registration.validation.code_missing"),
      (v: string) =>
        PARTICIPANT_CODE_REGEX.test(v) || this.$t("registration.validation.code_invalid")
    ]
  }
  mounted(): void {
    let query_token = this.$route.query.token
    if (query_token && query_token !== "") {
      this.registration.token = query_token as string
      this.token_provided = true
    }
  }
  async submit(): Promise<void> {
    if (this.valid) {
      try {
        this.registration.email = this.registration.email.toLowerCase()
        await RegistrationRepository.register(this.registration)
        this.successful = true
      } catch (error: any) {
        if (error.token) this.token_provided = false // if token invalid, keep input field visible
        this.error = error
      }
    }
  }
  to_login(): void {
    this.$router.push({ name: "login" })
  }
  async logout(): Promise<void> {
    await this.$store.dispatch("logout", false)
  }
  get language(): string {
    return navigator.language
  }
}

interface IError {
  email: string
  token: string
  participant_code: string
}
