




































import { Component, Vue } from 'vue-property-decorator'
import PasswordInput from "@/components/login/PasswordInput.vue";
import LoginRepository from '@/api/LoginRepository';

@Component({
  components: {
    PasswordInput
  }
})
export default class ResetPasswordForm extends Vue {
  input: {valid: boolean, password: string, token: string } = {
    valid: false,
    password: "",
    token: "",
  }
  error: string = ""
  success: boolean = false

  mounted(): void {
    const query_token = this.$route.query.token;
    if(query_token && query_token !== null && query_token !== "") {
      this.input.token = query_token as string;
    } else {
      this.error = this.$t('login.invalid_reset_link').toString();
    }
  }
  async submit(): Promise<void> {
    if(this.input.valid) {
      try {
        await LoginRepository.resetPassword(this.input.token, this.input.password);
        this.success = true;
      } catch (error) {
        this.error = this.$t('login.reset_error_too_similiar').toString();
        /*eslint-disable no-console */
        console.error("Error while resetting password 2: " + error);
      }
    }
  }
  goToReset(): void {
    this.$router.push({name: 'reset-password'});
  }
  goToLogin(): void {
    this.$router.push({name: 'login'});
  }
}
