import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"login-container fill-height",attrs:{"fluid":""}},[_c('div',{staticClass:"login-background"}),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","align-self":"center"}},[_c(VImg,{staticClass:"youcan-logo",attrs:{"src":require('@/assets/icons/YouCan.svg')}})],1)],1),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"4"}},[_c(VCard,{staticClass:"login"},[(_vm.mode === _vm.LoginMode.LOGIN)?[_c('h2',{staticClass:"login-title"},[_vm._v(_vm._s(_vm.$t('login.title')))]),_c('LoginForm'),_c(VBtn,{staticClass:"forgotPassword",attrs:{"type":"submit","outlined":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.goToReset.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('login.forgot_password'))+" ")])]:(_vm.mode === _vm.LoginMode.REQUEST_RESET)?[_c('h2',{staticClass:"login-title"},[_vm._v(_vm._s(_vm.$t('login.forgot_password')))]),_c('RequestResetForm')]:(_vm.mode === _vm.LoginMode.RESET)?[_c('h2',{staticClass:"login-title"},[_vm._v(_vm._s(_vm.$t('login.choose_new_password')))]),_c('ResetPasswordForm')]:(_vm.mode === _vm.LoginMode.REGISTRATION)?[_c('h2',{staticClass:"login-title"},[_vm._v(_vm._s(_vm.$t('registration.title')))]),_c('RegistrationForm')]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }