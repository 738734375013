

































import { Component, Vue } from "vue-property-decorator"
import PasswordInput from "@/components/login/PasswordInput.vue"
import { AlertState } from "@/store/types"

@Component({
  components: {
    PasswordInput
  }
})
export default class LoginForm extends Vue {
  input: { email: string; password: string } = { email: "", password: "" }
  rules: any = {
    email: [
      (v: string) => !!v || this.$t("email_validate.missing"),
      (v: string) => /.+@.+/.test(v) || this.$t("email_validate.invalid")
    ]
  }

  get alert(): AlertState {
    return this.$store.state.alert
  }

  async submit(): Promise<void> {
    let user_email = this.input.email.toLowerCase()
    await this.$store.dispatch("login", { email: user_email, password: this.input.password })
  }
}
