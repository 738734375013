





































import { Component, Prop, Vue } from 'vue-property-decorator'
import LoginForm from "@/components/login/LoginForm.vue";
import RequestResetForm from "@/components/login/RequestResetForm.vue";
import ResetPasswordForm from "@/components/login/ResetPasswordForm.vue";
import RegistrationForm from "@/components/login/RegistrationForm.vue";
import { LoginMode } from "@/utils/login_modes";

@Component({
  components: {
    LoginForm,
    RequestResetForm,
    ResetPasswordForm,
    RegistrationForm
  }
})
export default class Login extends Vue {
  @Prop({type: Number, default: LoginMode.LOGIN}) mode!: number
  LoginMode: typeof LoginMode = LoginMode

  created(): void {
    if(this.$store.getters.isAuthenticated && this.mode !== LoginMode.REGISTRATION) {
      if(this.$store.getters.getOnboardingPending) 
        this.$router.push({name: 'onboarding'});
      else 
        this.$router.push({name: 'home'});
    }
  }
  goToReset(): void {
    this.$router.push({name: 'reset-password'});
  }
}
