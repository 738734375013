


























import { Vue, Prop, Component, Watch } from "vue-property-decorator"
import { passwordValidators, passwordConfirmValidators } from "@/utils/password_validator"

@Component({})
export default class PasswordInput extends Vue {
  @Prop({ type: String, default: "" }) value!: string
  @Prop({ type: Boolean, default: false }) withIcon!: boolean
  @Prop({ type: Boolean, default: false }) confirmation!: boolean
  @Prop({ type: Boolean, default: false }) noValidation!: boolean
  @Prop({ type: String, default: "" }) passwordLabel!: string
  @Prop({ type: String, default: "" }) passwordConfirmLabel!: string

  password: string = ""
  password_confirm: string = ""
  show_password: boolean = false
  show_confirm: boolean = false
  validate_pw: boolean = true
  password_label: string = ""
  password_confirm_label: string = ""

  created(): void {
    this.password = this.value
    this.show_confirm = this.confirmation
    this.password_label = "" + this.passwordLabel
    this.password_confirm_label = "" + this.passwordConfirmLabel
  }

  @Watch("password")
  onPasswordChange(newValue: string) {
    this.$emit("input", newValue)
    // if (this.show_confirm) (this.$refs.confirm as any).validate()
  }

  get passwordRules(): any[] {
    if (this.noValidation) return []
    return passwordValidators((key: string) => this.$t(key))
  }
  get passwordConfirmationRules(): any[] {
    if (this.show_password) return [] // skip validation for confirm field if input visible
    return passwordConfirmValidators(this.password, (key: string) => this.$t(key))
  }
}
