























import { Component, Vue } from 'vue-property-decorator'
import LoginRepository from "@/api/LoginRepository";

@Component({})
export default class RequestResetForm extends Vue {
  input: {email: string} = {
    email: ''
  }
  rules: any = {
    email: [
      (v: string) => !!v || this.$t("email_validate.missing"),
      (v: string) => /.+@.+/.test(v) || this.$t("email_validate.invalid")
    ]
  }
  success: boolean = false

  async reset(): Promise<void> {
    const user_email = this.input.email.toLowerCase()

    if (user_email && user_email.length > 0) {
      try{
        await LoginRepository.requestPasswordReset(user_email);
        this.success = true;
      }catch(error){
        this.$store.dispatch('alert/error', this.$t('error_messages.reset_password'));
        /*eslint-disable no-console */
        console.error("Error while resetting password: " + error);

        // check for 400 -- set error
      }
    }
  }
}
