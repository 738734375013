import { NonAuthRepository as Repository } from "@/api/Repository";
import { IRegistrationRequest } from '@/models/IRegistrationRequest';

const resource = "/v1/registration/";

class RegistrationRepository {
  async register(params: IRegistrationRequest) {
    return await Repository.post(resource, params);
  }
}

export default new RegistrationRepository();
